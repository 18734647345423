import { createStore, createHook } from 'react-sweet-state';

export const FlyoutStore = createStore({
	name: 'FlyoutStore',
	initialState: {
		openFlyout: null, // Keeps track of the currently open flyout by its label
	},
	actions: {
		openFlyout:
			(label) =>
			({ setState }) => {
				setState({ openFlyout: label });
			},
		closeFlyout:
			(label = null) =>
			({ setState, getState }) => {
				if (label) {
					if (label === getState().openFlyout) {
						setState({ openFlyout: null });
					}
				} else {
					setState({ openFlyout: null });
				}
			},
	},
});

export const useFlyoutStore = createHook(FlyoutStore);
