import type { ContentOperationsQuery as ContentOperationsQueryType } from './__types__/ContentOperationsQuery';
import type { SpaceOperationsQuery as SpaceOperationsQueryType } from './__types__/SpaceOperationsQuery';

export enum OperationTypes {
	USE = 'use',
	LIMITED_USE = 'limited_use',
	EXTERNAL_COLLABORATOR_USE = 'external_collaborator_use',
	CREATE = 'create',
	READ = 'read',
	UPDATE = 'update',
	DELETE = 'delete',
	COPY = 'copy',
	MOVE = 'move',
	EXPORT = 'export',
	ARCHIVE = 'archive',
	PURGE = 'purge',
	PURGE_VERSION = 'purge_version',
	ADMINISTER = 'administer',
	RESTORE = 'restore',
	CREATE_SPACE = 'create_space',
	RESTRICT_CONTENT = 'restrict_content',
	CLEAR_PERMISSIONS = 'clear_permissions',
}

/** Params for fetching and checking operation */

export type TargetContentType = 'page' | 'blogpost' | 'space' | 'comment' | 'attachment';

export type checkSpaceOperationParams = {
	spaceKey: string;
	operation: OperationTypes;
	contentType: TargetContentType;
};

export type checkContentOperationParams = {
	contentId: string;
	operation: OperationTypes;
	contentType?: TargetContentType;
};

export type canPerformContentOperationParams = {
	operationCheckResult: ContentOperationsQueryType;
	operation: OperationTypes;
	contentType?: TargetContentType;
};
export type canPerformSpaceOperationParams = {
	operationCheckResult: SpaceOperationsQueryType;
	operation: OperationTypes;
	contentType: TargetContentType;
};
