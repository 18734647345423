import type { ComponentProps } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AddIcon from '@atlaskit/icon/utility/add';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

type CreateContentMenuItemProps = {
	onClick: () => void;
	href?: string;
};

export const CreateContentMenuItem = ({ onClick, href }: CreateContentMenuItemProps) => {
	const menuItemProps: Pick<
		ComponentProps<typeof MenuButtonItem>,
		'onClick' | 'elemBefore' | 'children'
	> = {
		onClick,
		elemBefore: <AddIcon label="" color="currentColor" />,
		children: <FormattedMessage {...i18n.create} />,
	};

	if (href) {
		return <MenuLinkItem {...menuItemProps} href={href} />;
	}

	return <MenuButtonItem {...menuItemProps} />;
};

const i18n = defineMessages({
	create: {
		id: 'create-blank-fabric-page.create-content-menu-item.create',
		description:
			'Label for a button that creates content in the space the user is currently viewing',
		defaultMessage: 'Create',
	},
});
