import type { ApolloQueryResult } from 'apollo-client';

import { getApolloClient } from '@confluence/graphql';

import type {
	ContentOperationsQuery as ContentOperationsQueryType,
	ContentOperationsQuery_content_nodes_operations,
} from './__types__/ContentOperationsQuery';
import { ContentOperationsQuery } from './ContentOperationsQuery.graphql';
import type {
	canPerformContentOperationParams,
	canPerformSpaceOperationParams,
	checkContentOperationParams,
	checkSpaceOperationParams,
} from './types';
import type {
	SpaceOperationsQuery as SpaceOperationsQueryType,
	SpaceOperationsQuery_space_operations,
} from './__types__/SpaceOperationsQuery';
import { SpaceOperationsQuery } from './SpaceOperationsQuery.graphql';

/**
 * Validate `operation` on `contentType` given `operationCheckResult` from `ContentOperationsQuery`
 * If `contentType` is not specified, type of main/parent content in `operationCheckResult` will be assumed
 */
export const canPerformContentOperation = ({
	operationCheckResult,
	operation,
	contentType,
}: canPerformContentOperationParams): boolean => {
	const permittedOperations = (operationCheckResult?.content?.nodes?.[0]?.operations ??
		[]) as ContentOperationsQuery_content_nodes_operations[];
	const targetContentType = !!contentType
		? contentType
		: operationCheckResult?.content?.nodes?.[0]?.type;

	if (!targetContentType) return false;

	return permittedOperations.some(
		(permittedOperation) =>
			permittedOperation?.operation === operation &&
			permittedOperation?.targetType === targetContentType,
	);
};

/** Validate `operation` on `contentType` given `operationCheckResult` from `SpaceOperationsQuery */
export const canPerformSpaceOperation = ({
	operationCheckResult,
	operation,
	contentType,
}: canPerformSpaceOperationParams): boolean => {
	// prettier-ignore
	const permittedOperations = (operationCheckResult?.space?.operations ?? []) as SpaceOperationsQuery_space_operations[];

	return permittedOperations.some(
		(permittedOperation) =>
			permittedOperation?.operation === operation && permittedOperation?.targetType === contentType,
	);
};

/** Functions to query and check operation result */

/**
 * Retrieve operations for content with contentId, then check if user permitted to perform `operation` on target `contentType`
 * @param contentId id of target entity
 * @param operation operation type
 * @param contentType if empty, then content type of content with id contentId will be used
 */
export const checkOperationForContent = async ({
	contentId,
	operation,
	contentType,
}: checkContentOperationParams): Promise<boolean> => {
	const apolloClient = getApolloClient();
	const contentOperationsQueryResult: ApolloQueryResult<ContentOperationsQueryType> =
		await apolloClient.query({
			query: ContentOperationsQuery,
			variables: {
				contentId,
			},
		});
	return canPerformContentOperation({
		operationCheckResult: contentOperationsQueryResult.data,
		operation,
		contentType,
	});
};

/**
 * Retrieve operations space, then check if user permitted to perform `operation` on target `contentType` within space
 */
export const checkOperationForSpace = async ({
	spaceKey,
	operation,
	contentType,
}: checkSpaceOperationParams): Promise<boolean> => {
	const apolloClient = getApolloClient();
	const spaceOperationsQueryResult: ApolloQueryResult<SpaceOperationsQueryType> =
		await apolloClient.query({
			query: SpaceOperationsQuery,
			variables: {
				spaceKey,
			},
		});
	return canPerformSpaceOperation({
		operationCheckResult: spaceOperationsQueryResult.data,
		operation,
		contentType,
	});
};
